<template>
    <div class="col-12 viewTabTwo mb-3">
        <div class="bg-white tradeChart-element mt-5 mt-sm-0">
            <ul id="tabs-nav" class="zuluTabs d-flex align-items-center border-bottom mb-0">
                <li :class="[{ 'active': tTab == 6 }]"><a class="py-3" href="javascript:void(0)"
                        @click="changeTtab(6)">{{ $t('leader_profile.tradingHistory') }}</a></li>
                <li :class="[{ 'active': tTab == 7 }]"><a class="py-3" href="javascript:void(0)"
                        @click="changeTtab(7)">{{ $t('leader_profile.openPositions') }}</a></li>
            </ul>
            <div class="dataTable table-responsive position-relative" v-if="tTab == 6">
                <Loader :classname="'innerLoader'" v-if="store.singleLoading"></Loader>
                <table id="example" class="table currencyTable"
                    v-if="Object.keys(store.tradesHistory).length && store.tradesHistory.content && store.tradesHistory.content.length">
                    <thead>
                        <tr>
                            <th class="ps-md-4 pointer"
                                :class="[{ 'sorted': arrowClass == 'currency' }, { 'desc': arrowClassBoth == 0 }, { 'asc': arrowClassBoth == 1 }]"
                                @click="sortList('currency')">{{ $t('leader_profile.portfolioName') }}</th>
                            <!-- <th class="pointer" :class="[{'sorted':arrowClass=='dateOpen'},{'desc':arrowClassBoth==0},{'asc':arrowClassBoth==1}]" @click="sortList('dateOpen')">Date Open</th> -->
                            <th class="pointer"
                                :class="[{ 'sorted': arrowClass == 'dateClosed' }, { 'desc': arrowClassBoth == 0 }, { 'asc': arrowClassBoth == 1 }]"
                                @click="sortList('dateClosed')">{{ $t('leader_profile.portfolioDateClosed') }}</th>
                            <!-- <th class="pointer" :class="[{'sorted':arrowClass=='tradeType'},{'desc':arrowClassBoth==0},{'asc':arrowClassBoth==1}]" @click="sortList('tradeType')">TYPE</th> -->
                            <th class="pointer"
                                :class="[{ 'sorted': arrowClass == 'lots' }, { 'desc': arrowClassBoth == 0 }, { 'asc': arrowClassBoth == 1 }]"
                                @click="sortList('lots')">{{ $t('leader_profile.portfolioSTD') }}</th>
                            <th>{{ $t('leader_profile.portfolioOpenClose') }}</th>
                            <th class="pointer"
                                :class="[{ 'sorted': arrowClass == 'maxProfit' }, { 'desc': arrowClassBoth == 0 }, { 'asc': arrowClassBoth == 1 }]"
                                @click="sortList('maxProfit')">{{ $t('leader_profile.portfolioHigh') }}</th>
                            <th class="pointer"
                                :class="[{ 'sorted': arrowClass == 'worstDrawdown' }, { 'desc': arrowClassBoth == 0 }, { 'asc': arrowClassBoth == 1 }]"
                                @click="sortList('worstDrawdown')">{{ $t('leader_profile.portfolioLow') }}</th>
                            <th class="pointer"
                                :class="[{ 'sorted': arrowClass == 'interest' }, { 'desc': arrowClassBoth == 0 }, { 'asc': arrowClassBoth == 1 }]"
                                @click="sortList('interest')">{{ $t('leader_profile.portfolioRoll') }}</th>
                            <th class="pointer"
                                :class="[{ 'sorted': arrowClass == 'net_pnl' }, { 'desc': arrowClassBoth == 0 }, { 'asc': arrowClassBoth == 1 }]"
                                @click="sortList('net_pnl')">{{ $t('leader_profile.portfolioProfit') }}</th>
                            <th>{{ $t('leader_profile.portfolioTotal') }}</th>
                            <th>&nbsp;</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="item, key in store.tradesHistory.content" :key="key">
                            <td>
                                <!-- <span class="medium">{{item.currency}}</span> -->
                                <div class="d-flex align-items-center">
                                    <!-- <span class="Icon me-2"><img @click="tradeDetail = item" class="pointer" src="/assets/images/teslaIcon.png" alt="EUR/USD" /></span> -->
                                    <div class="tooltipbutton me-3">
                                        <span class="tooltiptext left">
                                            <p class="mb-0 f-14">{{ $t('leader_profile.portfolioTraderTicket') }}<strong>
                                                    {{ item.providerTicket }}</strong></p>
                                            <p class="mb-0 f-14">{{ $t('leader_profile.portfolioBrokerTicket') }}<strong>
                                                    {{ item.brokerTicket }}</strong></p>
                                            <p class="mb-0 f-14">{{ $t('leader_profile.portfolioAmount') }}<strong>
                                                    {{ item.amount }} {{ item.transactionCurrency }}</strong></p>
                                            <!-- <span class="d-block secondary">Orange for insufficient capital</span> View more information <span class="pointer link"> here</span>. -->
                                        </span>
                                        <vue-feather class="smallSize" type="alert-circle"></vue-feather>
                                    </div>
                                    <div class="nameView">
                                        <div class="d-flex align-items-center">
                                            <p class="mb-0 f-14 medium">{{ item.currency }}</p>
                                            <!-- <span class="cfdTrade ms-1">CFD</span> -->
                                        </div>
                                        <div class="d-flex align-items-center">
                                            <p class="mb-0 midgray f-11 medium">{{ dateFormat(item.dateOpen) || '' }}
                                                {{ item.dateOpen ? ',' : '' }} {{ timeFormat(item.dateOpen) || '' }}</p>
                                            <span class="status"
                                                :class="[{ 'buy': item.tradeType == 'BUY' }, { 'sell': item.tradeType == 'SELL' }]">{{ item.tradeType }}</span>
                                        </div>
                                    </div>
                                </div>
                            </td>
                            <!-- <td>{{dateFormat(item.dateOpen) || '-'}} <span class="d-block"> {{timeFormat(item.dateOpen) || '-'}}</span></td> -->
                            <td> {{ dateFormat(item.dateClosed) || '-' }} <span class="d-block"> {{ timeFormat(item.dateClosed)
                                || '' }}</span></td>
                            <!-- <td>{{item.tradeType}}</td> -->
                            <td>{{ item.lots }}</td>

                            <td><b>{{ item.priceOpen ? parseFloat(item.priceOpen).toFixed(5) : '-' }}</b><span
                                    class="d-block"> {{ item.priceClosed ? parseFloat(item.priceClosed).toFixed(5) :
                                        '' }}</span></td>
                            <td>{{ item.maxProfit }}</td>
                            <td>{{ item.worstDrawdown }}</td>
                            <td>{{ item.interest }}</td>
                            <td :class="[{ 'error1': item.netPnl < 0 }, { 'success1': item.netPnl > 0 }]">
                                <b>{{ (parseFloat(item.netPnl) > 0) ? '+' :
                                    '-' }}${{ parseFloat(Math.abs(item.netPnl)).toFixed(2) }}</b> <span
                                    class="d-block">{{ (parseFloat(item.pips) > 0) ? '+' :
                                        '' }}{{ parseFloat(item.pips).toFixed(2) }} pips</span></td>
                            <td><b>${{ parseFloat(item.totalAccumulatedPnl).toFixed(2) }}</b><span
                                    class="d-block">{{ item.totalAccumulatedPips.toLocaleString('en') }}
                                    {{ $t('leader_profile.portfolioPips') }}</span></td>
                            <!-- <td>
                                <div class="position-relative dropdown">
                                    <button class="morebtn dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                        <i class="f-16 fa fa-ellipsis-v" aria-hidden="true"></i>
                                    </button>
                                    <ul class="dropdown-menu dropdown-menu-end p-0" aria-labelledby="dropdownMenuButton1">
                                        <li><a class="dropdown-item f-14" href="javascript:void(0)">Suggest</a></li>
                                        <li><a class="dropdown-item f-14" href="javascript:void(0)">Share</a></li>
                                        <li><a class="dropdown-item f-14" href="javascript:void(0)">Compare</a></li>
                                    </ul>
                                </div>
                            </td> -->
                        </tr>
                    </tbody>
                </table>
                <Nodata v-else></Nodata>

            </div>
            <div class="tableFooter d-block d-md-flex align-items-center justify-content-center justify-content-md-between"
                v-if="tTab == 6 && Object.keys(store.tradesHistory).length && store.tradesHistory.content && store.tradesHistory.content.length && store.tradesHistory.totalElements > 10">
                <div class="d-flex align-items-center justify-content-center justify-content-md-start">
                    <p class="f-13 mb-0 me-2">{{ ((page * limit) - limit) + 1 }} - 
                        <span v-if="store.tradesHistory?.content?.length < limit"> {{ store.tradesHistory.totalElements }} </span>
                        <span v-else> {{limit * page}} </span>
                         of
                            {{ store.tradesHistory.totalElements }} {{ $t('leader_profile.portfolioTradesshow') }}</p>
                            <select class="f-13 form-select border-less w-auto border-0" v-model="limit"
                                @change="page = 1; pageListing()">
                                <option v-if="store.tradesHistory.totalElements > 10" value="10">10</option>
                                <option v-if="store.tradesHistory.totalElements > 20" value="20">20</option>
                                <option v-if="store.tradesHistory.totalElements > 50" value="50">50</option>
                                <option v-if="store.tradesHistory.totalElements > 100" value="100">100</option>
                            </select>
                            <p class="f-13 mb-0 ms-2">{{ $t('leader_profile.portfolioTrades') }}</p>
                </div>
                <Pagination :pageNo="page" :totalRecords="store.tradesHistory.totalElements" :perPage="limit"></Pagination>
            </div>
            <div class="dataTable table-responsive position-relative" v-if="tTab == 7">
                <Loader :classname="'innerLoader'" v-if="store.singleLoading"></Loader>
                <table class="table currencyTable" v-if="store.openPosition.length">
                    <thead>
                        <tr>
                            <th class="ps-md-4 pointer" :class="[{ 'sorted': arrowClass == 'currencyName' }]"
                                @click="sortList('currencyName')">{{ $t('leader_profile.portfolioCURRENCY') }}</th>
                            <th class="pointer"
                                :class="[{ 'sorted': arrowClass == 'tradeType' }, { 'desc': arrowClassBoth == 0 }, { 'asc': arrowClassBoth == 1 }]"
                                @click="sortList('tradeType')">{{ $t('leader_profile.portfolioTYPE') }}</th>
                            <th class="pointer"
                                :class="[{ 'sorted': arrowClass == 'stdLotds' }, { 'desc': arrowClassBoth == 0 }, { 'asc': arrowClassBoth == 1 }]"
                                @click="sortList('stdLotds')">{{ $t('leader_profile.portfolioSTD') }}</th>
                            <th class="pointer"
                                :class="[{ 'sorted': arrowClass == 'dateTime' }, { 'desc': arrowClassBoth == 0 }, { 'asc': arrowClassBoth == 1 }]"
                                @click="sortList('dateTime')">{{ $t('leader_profile.portfolioDateOpen') }}</th>
                            <th class="pointer"
                                :class="[{ 'sorted': arrowClass == 'entryRate' }, { 'desc': arrowClassBoth == 0 }, { 'asc': arrowClassBoth == 1 }]"
                                @click="sortList('entryRate')">{{ $t('leader_profile.portfolioOpen') }}</th>
                            <th class="pointer"
                                :class="[{ 'sorted': arrowClass == 'stop' }, { 'desc': arrowClassBoth == 0 }, { 'asc': arrowClassBoth == 1 }]"
                                @click="sortList('stop')">{{ $t('leader_profile.portfolioStop') }}</th>
                            <th class="pointer"
                                :class="[{ 'sorted': arrowClass == 'limit' }, { 'desc': arrowClassBoth == 0 }, { 'asc': arrowClassBoth == 1 }]"
                                @click="sortList('limit')">{{ $t('leader_profile.portfolioLimit') }}</th>
                            <th class="pointer"
                                :class="[{ 'sorted': arrowClass == 'currentRate' }, { 'desc': arrowClassBoth == 0 }, { 'asc': arrowClassBoth == 1 }]"
                                @click="sortList('currentRate')">{{ $t('leader_profile.portfolioCURRENT') }}</th>
                            <th class="pointer"
                                :class="[{ 'sorted': arrowClass == 'floatingPnl' }, { 'desc': arrowClassBoth == 0 }, { 'asc': arrowClassBoth == 1 }]"
                                @click="sortList('floatingPnl')">{{ $t('leader_profile.portfolioProfit') }}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="item, key in store.openPosition" :key="key">
                            <td>
                                <div class="tooltipbutton me-3">
                                    <span class="tooltiptext left">
                                        <p class="mb-0 f-14">{{ $t('leader_profile.portfolioTraderTicket') }}<strong>
                                                {{ item.providerTicket || '-' }}</strong></p>
                                        <p class="mb-0 f-14">{{ $t('leader_profile.portfolioBrokerTicket') }}<strong>
                                                {{ item.brokerTicket || '-' }}</strong></p>
                                        <p class="mb-0 f-14">{{ $t('leader_profile.portfolioAmount') }}<strong> {{ item.amount
                                            || '-' }} {{ item.transactionCurrency || '' }}</strong></p>
                                    </span>
                                    <vue-feather class="smallSize" type="alert-circle"></vue-feather>
                                </div>
                                <span class="medium">{{ item.currencyName || '-' }}</span>
                            </td>
                            <td :class="[{ 'buy': item.tradeType == 'BUY' }, { 'sell': item.tradeType == 'SELL' }]">{{ item.tradeType ||
                                '-' }}</td>
                            <td>{{ item.stdLotds || '-' }}</td>
                            <td>{{ dateFormat(item.dateTime) }} <span class="d-block"> {{ timeFormat(item.dateTime) }}</span>
                            </td>
                            <td>{{ item.entryRate || '-' }}</td>
                            <td>{{ item.stop }}</td>
                            <td>{{ item.limit || '-' }}</td>
                            <td>{{ store.allPrices[item.currencyName] ? store.allPrices[item.currencyName].buyPrice :
                                item.currentRate }}</td>
                            <td :class="[{ 'error1': COMMON.getPNL(item) < 0 }, { 'success1': COMMON.getPNL(item) >= 0 }]"><b>
                                    {{ (COMMON.getPNL(item) > 0) ? '' : '-' }}${{ Math.abs(COMMON.getPNL(item)) }}</b>
                                <!-- <span class="d-block">{{parseFloat(item.floatingPips).toFixed(2)}} pips</span> -->
                            </td>
                            <!-- <td v-else>$0</td> -->
                        </tr>
                    </tbody>
                </table>
                <Nodata v-else></Nodata>
                <!-- <div class="tableFooter d-flex align-items-center justify-content-between" v-if="tTab == 7 && store.openPosition.length > limit">
                    <div class="d-flex align-items-center">
                        <p class="f-14 mb-0 me-2">{{ ((page * limit) - limit) + 1 }} - {{limit * page}} of {{store.openPosition.length}} trades Show</p>
                        <select class="f-14 form-select border-less w-auto border-0" v-model="limit" @change="page = 1;openPositionList()">
                            <option v-if="store.openPosition.length >= 10" value="10">10</option>
                            <option v-if="store.openPosition.length >= 20" value="20">20</option>
                            <option v-if="store.openPosition.length >= 50" value="50">50</option>
                            <option v-if="store.openPosition.length >= 100" value="100">100</option>
                        </select>
                        <p class="f-14 mb-0 ms-2">trades</p>
                    </div>
                    <Pagination :pageNo="page" :totalRecords="store.openPosition.length" :perPage="limit" type="openposition"></Pagination>
                </div> -->
            </div>
        </div>
    </div>
    <!--Modal iframe trade details-->
    <div id="modaltradeDetail" class="modal fade modal-680" :class="[{ 'show d-block': Object.keys(tradeDetail).length > 0 }]"
        v-if="Object.keys(tradeDetail).length > 0">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
                <div class="modal-header border-0 bg-grey">
                    <p class="mb-0 f-16 fw-300">Trade Details #{{ tradeDetail.providerTicket }}</p>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"
                        @click="tradeDetail = {}"></button>
                </div>
                <div class="modal-body">
                    <ul class="d-flex align-items-center flex-wrap justify-content-between mb-0">
                        <li class="w-50 text-start"><span class="inactive f-12">Trader Ticket</span>
                            <h5 class="f-16">{{ tradeDetail.providerTicket }}</h5>
                        </li>
                        <li class="w-50 text-start ps-2"><span class="inactive f-12">Broker Ticket</span>
                            <h5 class="f-16">{{ tradeDetail.brokerTicket }}</h5>
                        </li>
                        <li class="w-100 text-start"><span class="inactive f-12">Amount</span>
                            <h5 class="f-16">{{ tradeDetail.amount }}{{ tradeDetail.transactionCurrency }}</h5>
                        </li>
                        <li class="w-50 text-start"><span class="inactive f-12">Highest Profit</span>
                            <h5 class="f-16"
                                :class="[{ 'red': tradeDetail.maxProfit < 0 }, { 'green': tradeDetail.maxProfit > 0 }]">
                                {{ tradeDetail.maxProfit }} pips On {{ dateTimePopup(tradeDetail.dateOpen) }}</h5>
                        </li>
                        <li class="w-50 text-start ps-2"><span class="inactive f-12">Worst Drawdown</span>
                            <h5 class="f-16"
                                :class="[{ 'red': tradeDetail.worstDrawdown < 0 }, { 'green': tradeDetail.worstDrawdown > 0 }]">
                                {{ tradeDetail.worstDrawdown }} pips On {{ dateTimePopup(tradeDetail.dateClosed) }}</h5>
                        </li>
                        <li class="w-50 text-start"><span class="inactive f-12">Interest</span>
                            <h5 class="f-16">${{ tradeDetail.interest }}</h5>
                        </li>
                        <li class="w-50 text-start ps-2"><span class="inactive f-12">Commission</span>
                            <h5 class="f-16">${{ tradeDetail.commission }}</h5>
                        </li>
                    </ul>
                    <iframe width="100%" height="350"
                        :src="'//fullpack.zulutrade.com/#/trading?mode=embed&amp;guest=1&amp;adminGroupId=1&amp;theme=Light&amp;hideButtons=1&amp;chart=' + (tradeDetail.currency).replace(/\\|\//g, '') + '_' + dateTimeFormat(tradeDetail.dateOpen) + '&amp;timeFrom=' + tradeDetail.dateOpen + '&amp;timeTo=' + tradeDetail.dateClosed"></iframe>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import moment from "moment"
import Pagination from "@/components/shared/pagination.vue"
import { myStore } from "@/store/pinia-store";
import $ from "jquery";
import PriceFeed from "@/store/stomp";
export default {
    setup() {
        const store = myStore();
        return { store };
    },
    data() {
        return {
            tab: 1,
            tTab: 6,
            tradeDetail: {},
            page: 1,
            limit: 10,
            sortedbyASC: 1,
            sortTable: {
                currency: 0,
                tradeType: 0,
                dateOpen: 0,
                dateClosed: 0,
                lots: 0,
                maxProfit: 0,
                worstDrawdown: 0,
                interest: 0,
                net_pnl: 0,
            },
            sortParameter: ['dateClosed', 'desc'],
            arrowClass: '',
            arrowClassBoth: '',
            tradingprofitTab: '',
            pageArr: [],
            currencyArr: []
        };
    },
    components: {
        Pagination
    },
    watch: {
        '$route.query.t'() {
            this.resetPage()
            this.pageListing()
        },
        '$route.params.id'() {
            this.pageListing()
        },
        'tTab'(){
            this.resetPage()
            this.currencyArr = []
        }
    },
    methods: {
        CharttabFilters(type) {
            this.tradingprofitTab = type
            if (this.tab == 1) {
                this.$refs.profit.openTrades = false;
                this.$refs.profit.traders = 1
            }
        },
        changeTab(val) {
            this.tab = val
            if (this.tradingprofitTab == 'ROI' && parseInt(this.tab) != 1) {
                this.tradingprofitTab = this.store.traderDetail.trader.stats.profile.baseCurrencyName
            }
        },
        changeTtab(val) {
            this.tTab = val
            if (this.tTab == 6) {
                this.pageListing()
            } else {
                this.openPositionList()
            }
        },
        dateFormat(date) {
            if (date) {
                let format = moment(date).toString()
                return moment(format).format("YYYY-MM-DD")
            }
            // if(date){
            //     let d = moment(date)
            //     let tz = -(new Date().getTimezoneOffset())
            //     d.add(tz, 'minutes')
            //     return moment(d).format('YYYY/MM/DD hh:mm')
            // }
        },
        timeFormat(date) {
            if (date) {
                let format = moment(date).toString()
                return moment(format).format("hh:mm:ss a")
            }
        },
        dateTimeFormat(date) {
            if (date) {
                let format = moment(date).toString()
                return moment(format).format("YYYY-MM-DD-hh-mm")
            }
        },
        dateTimePopup(date) {
            if (date) {
                let format = moment(date).toString()
                return moment(format).format("YYYY-MM-DD hh:mm:ss")
            }
        },
        sortList(sortBy) {
            if (this.tTab == 6) {
                for (let k in this.sortTable) {
                    if (k == sortBy) {
                        if (this.sortTable[k] == 0) {
                            this.sortParameter = [sortBy, 'desc']
                            this.pageListing()
                            this.arrowClass = sortBy
                            this.arrowClassBoth = this.sortTable[k]
                            this.sortTable[k] = 1
                        } else {
                            this.sortParameter = [sortBy, 'asc']
                            this.pageListing()
                            this.arrowClass = sortBy
                            this.arrowClassBoth = this.sortTable[k]
                            this.sortTable[k] = 0
                        }
                    }
                }
            } else {
                if (this.sortedbyASC == 1) {
                    this.store.openPosition.sort((x, y) => (x[sortBy] > y[sortBy] ? -1 : 1));
                    this.arrowClass = sortBy
                    this.arrowClassBoth = this.sortedbyASC
                    this.sortedbyASC = 0;
                } else {
                    this.store.openPosition.sort((x, y) => (x[sortBy] < y[sortBy] ? -1 : 1));
                    this.arrowClass = sortBy
                    this.arrowClassBoth = this.sortedbyASC
                    this.sortedbyASC = 1;
                }
            }
        },
        pageListing() {
            if (this.$route.query.t) {
                let formData = {
                    timeframe: this.$route.query.t,
                    page: this.page - 1,
                    size: this.limit,
                    sort: this.sortParameter
                }
                if (Object.keys(this.$parent.currencyFilter).length && Object.keys(this.store.currencyPairs).length) {
                    this.currencyArr = [];
                    for (var key in this.$parent.currencyFilter) {
                        this.store.currencyPairs.forEach(val => {
                            if (this.$parent.currencyFilter[key] != '' && val.name == this.$parent.currencyFilter[key]) {
                                this.currencyArr.push(val.id)
                            }

                        })
                    }
                    if (this.currencyArr.length) {
                        formData['id'] = this.currencyArr.join(',')
                    }
                }
                this.store.callTradingHistory(formData, true, this.$route.params.id)
            }
        },
        openPositionList() {
            let formData = {}
            if (Object.keys(this.$parent.currencyFilter).length && Object.keys(this.store.currencyPairs).length) {
                this.currencyArr = [];
                for (var key in this.$parent.currencyFilter) {
                    this.store.currencyPairs.forEach(val => {
                        if (this.$parent.currencyFilter[key] != '' && val.name == this.$parent.currencyFilter[key]) {
                            this.currencyArr.push(val.id)
                        }

                    })
                }
                if (this.currencyArr.length) {
                    formData['cId'] = this.currencyArr.join(',')
                }
            }
            this.store.callOpenPosition(formData, true, this.$route.params.id).then(() => {
                if (this.store.openPosition && this.store.openPosition.length) {
                    this.sortList('dateTime')
                    //this.pagePhotoChange()
                }
            })
        },
        pagePhotoChange() {
            this.pageArr.splice(0, this.pageArr.length);
            let start = 0, end = 0;
            start = this.limit * (this.page - 1);
            end = start + this.limit;
            if (end > this.store.openPosition.length) end = this.store.openPosition.length;
            for (let i = start; i < end; i++) {
                this.pageArr.push(this.store.openPosition[i]);
            }
            //console.log(this.pageArr)
        },
        resetPage(){
            this.page = 1
        }
    },
    updated() {
        this.$parent.divHeigh = $('.div-height').height() + 30;
        // console.log('12',this.currencyArr,Object.keys(this.$parent.currencyFilter).length)
    },
    created() {
        PriceFeed.getInstance(`wss://www.zulutrade.com:443/api/ws/zulutradewsclient/websocket?access_token=${this.store.user.access_token}`, `/topic/feed/group/1000`).activate();
        if (this.store.traderDetail.trader.stats.profile.baseCurrencyName) {
            this.tradingprofitTab = this.store.traderDetail.trader.stats.profile.baseCurrencyName
        }
        this.pageListing()
    },
}
</script>
<style>.table.currencyTable tr th.error1 {
    background-color: #ffc5bf !important;
}</style>
